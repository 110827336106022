/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Other Info"}>
        <SiteHeader />

        <Column className="pb--60 pt--60" name={"i1u8hbrxfba"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Important Information to know before the Wedding"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Gifts"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Your presence at our wedding is the greatest gift of all! We underline that gifts are not expected. If, however, you insist on a gift, we'll be grateful for a cash gift to contribute to our savings for our future family life :)"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Dress Code and Weather"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Dress Code</span>:&nbsp;Cocktail Attire\n<br><span style=\"font-weight: bold;\">Weather</span>:&nbsp;Malaga benefits from the famous Mediterranean climate of the Andalucia region. In April, the average temperature is a pleasant 21°C. The average low reaches 13°C on rare cooler days. We don't expect any rainfall, but bring a jacket just in case!&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Kids are Welcome!"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"We love you and we love (your) kids! So do not hesitate to bring them with you. We will create a kids-friendly zone so that the little ones can have some fun and you can enjoy the party! Please let us know through the form if you plan to bring kids."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--25 pr--25" name={"prazdna_sekcee"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}